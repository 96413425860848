<template>
<div class="page" v-loading="pageLoading">
  <div class="right">
    <h2 class="classify-title" style="font-size: 20px">{{ from }}</h2>
    <div class="player" id="player"></div>
    <p>{{ name }}</p>
  </div>

  <div class="left" v-if="from == '直播'">
    <div class="classify" style="margin-bottom: 30px">
      <h2 class="classify-title">热门{{ from }}</h2>
      <div class="hot-item" v-for="(item, index) of topData" :key="index" @click="liveplay(item)">
        <van-image class="cover" 
        :error-icon="$placeImg"
          :loading-icon="$placeImg"
        fit="cover" :src="item.cover" />
        <div class="detail">
          <p class="title">{{ item.name }}</p>
          <!-- <p class="subtitle">{{ item.description }}</p> -->
        </div>
      </div>
      <van-empty style="margin: 0 auto" v-if="!topData.length" class="emptySmall" description="暂无数据" />
    </div>
  </div>
  <div class="left" v-if="from == '微视频'">
    <div class="classify" style="margin-bottom: 30px">
      <h2 class="classify-title">热门{{ from }}</h2>
      <div class="hot-item" v-for="(item, index) of topData" :key="index" @click="liveplay2(item)">
        <van-image class="cover" 
        :error-icon="$placeImg"
          :loading-icon="$placeImg"
        fit="cover" :src="item.cover_url" />
        <div class="detail">
          <p class="title">{{ item.name }}</p>
          <!-- <p class="subtitle">{{ item.description }}</p> -->
        </div>
      </div>
      <van-empty style="margin: 0 auto" v-if="!topData.length" class="emptySmall" description="暂无数据" />
    </div>
  </div>
  <div class="left" v-if="from == '课程'">
    <div class="classify" style="margin-bottom: 30px">
      <h2 class="classify-title">热门{{ from }}</h2>
      <div class="hot-item" v-for="(item, index) of topData" :key="index" @click="jumpDetail(item)">
        <div class="tip">
              <!-- is_vip_flat 判断是否会员商品 0不是 1是  is_free 判断是否限免 2限免，1不限免-->
              <img :src="$free" v-if="item.is_free==2"/>
              <img :src="$vip" v-else-if="item.is_vip_flat==1"/>
              <img :src="$noVip" v-else>
            </div>
        <van-image class="cover" 
        :error-icon="$placeImg"
          :loading-icon="$placeImg"
        fit="cover" :src="item.master_cover" />
        <div class="detail">
          <p class="title">{{ item.name }}</p>
          <!-- <p class="subtitle">{{ item.description }}</p> -->
        </div>
      </div>
      <van-empty style="margin: 0 auto" v-if="!topData.length"  class="emptySmall" description="暂无数据" />
    </div>
  </div>
</div>
</template>

<script>

export default {
  name: "detail",
  data() {
    return {
      topData: [],
      pageLoading: false,
      id: "",
      type: "",
      player: "",
      name: "",
      from: "",
      url: "",
      goods_id: '',
      videoId:'',

      duration:0,
      currentTime:'',
      timeStart:0,
      totalTime:0,
    };
  },
  mounted() {
    this.$router.onReady(async () => {
      this.id = this.$route.query.id;
      this.goods_id = this.$route.query.goods_id;
      this.videoId = this.$route.query.videoId;
      this.type = this.$route.query.type;
      this.name = this.$route.query.name;
      this.from = this.$route.query.from;
      this.url = this.$route.query.url ? this.$route.query.url : "";
      if(this.from == '微视频'){
        this.$store.commit('SET_HEADER','media')
      }else if(this.from == '直播'){
        this.$store.commit('SET_HEADER','live')
      }
      this.getInit()
    });
  },

  methods: {
    async getInit(){
      if(this.id){
        this.$api({
          method: "get",
          url: "/api/livestream/click",
          data: {
            id:this.id,
          },
        });
      }
      if (!this.url || this.url==='undefined') {
        this.onCCH5PlayerLoaded();
      } else {
        console.log('url')
        this.player = new Aliplayer({
            id: "player",
            width: "100%",
            height: "480px",
        
            source: this.url,
            cover: "",
            skinLayout: [{
                name: "bigPlayButton",
                align: "blabs",
                x: 30,
                y: 80
              },
              {
                name: "H5Loading",
                align: "cc",
              },
              {
                name: "errorDisplay",
                align: "tlabs",
                x: 0,
                y: 0
              },
              {
                name: "infoDisplay"
              },
              {
                name: "tooltip",
                align: "blabs",
                x: 0,
                y: 56
              },
              {
                name: "thumbnail"
              },
              {
                name: "controlBar",
                align: "blabs",
                x: 0,
                y: 0,
                children: [{
                    name: "progress",
                    align: "blabs",
                    x: 0,
                    y: 44
                  },
                  {
                    name: "playButton",
                    align: "tl",
                    x: 15,
                    y: 12
                  },
                  {
                    name: "timeDisplay",
                    align: "tl",
                    x: 10,
                    y: 7
                  },
                  {
                    name: "fullScreenButton",
                    align: "tr",
                    x: 10,
                    y: 12
                  },
                  {
                    name: "subtitle",
                    align: "tr",
                    x: 15,
                    y: 12
                  },
                  {
                    name: "volume",
                    align: "tr",
                    x: 5,
                    y: 10
                  },
                ],
              },
            ],
          },
          function (player) {
            console.log("The player is created.");
          }
        );
   
      if(this.type=='ali' || this.type=='url'){
        console.log('typeAli')
        this.player.on('play',()=>{
          this.videPlay()
        })
        this.player.on('pause',()=>{
          this.videoPause()
        })
      }
      if(this.type=='cc'){
        this.player.play(()=>{
          this.videPlay()
        })
        console.log(this.player)
        // this.player.pause(()=>{
        //   this.videoPause()
        // })
      }
      }
      if (this.$route.query.from == "直播") {
        let res = await this.$api({
          method: "get",
          url: "/api/livestream/hot",
        });
        if (res.code == 0) {
          this.topData = res.data;
        }
      } else if (this.$route.query.from == "微视频") {
        let res = await this.$api({
          method: "get",
          host: "https://saas.amfakids.cn",
          url: "/api/v1/short/video/hotvideo",
        });
        if (res.code == 200) {
          this.topData = res.data.video_arr;
        }
      } else if(this.goods_id) {
        let res = await this.$api({
          method: "get",
          url: "/api/platform/goods/detail",
          data: {
            goods_id: this.goods_id,
            plat: this.plat,
          },
        });
        if (res.code == 0) {
          this.topData = res.data.top_list;
        }
      }
    },
    liveplay(item) {
      let { name, video_url,id,video_id ,goods_id } = item;
      
      if(video_url || video_id){
        this.$store.commit('SET_VIDEO',null)
          this.$router.push(
        `./player?id=${id}&videoId=${video_id}&name=${name}&url=${encodeURI(video_url)}&from=直播&goods_id=${goods_id}&type=ali`
      );
      }else{
          this.$toast('没有视频');
      }
      // let {
      //   name,
      //   video_url,
      //   id
      // } = item;
      // this.name = name;
      // this.url = video_url;
      // this.player.loadByUrl(this.url);
      // this.$router.push(`./player?url=${}`)
    },
    jumpDetail(item) {
      this.$router.push(`./detail?id=${item.id}`)
    },
    liveplay2(item) {
      let {
        name,
        url,
        id
      } = item;
      this.name = name;
      this.url = url;
      this.player.loadByUrl(this.url);
    },
    async onCCH5PlayerLoaded() {
      let id = this.videoId;
      let type = this.type;
      // let height = document.getElementById('player').style.height
        // console.log(height)
      console.log('获取视频')
      if (type == "ali") {
        console.log('aliID')
        let res = await this.$api({
          method: "get",
          url: "/api/video/play-auth/get",
          data: {
            video_id: id,
          },
        });
        if (res.code == 0) {
          this.player = new Aliplayer({
              id: "player",
              width: "100%",
              height: "480px",
              vid: res.data.videoId,
              playauth: res.data.playAuth,
              skinLayout: [{
                  name: "bigPlayButton",
                  align: "blabs",
                  x: 30,
                  y: 80
                },
                {
                  name: "H5Loading",
                  align: "cc",
                },
                {
                  name: "errorDisplay",
                  align: "tlabs",
                  x: 0,
                  y: 0
                },
                {
                  name: "infoDisplay"
                },
                {
                  name: "tooltip",
                  align: "blabs",
                  x: 0,
                  y: 56
                },
                {
                  name: "thumbnail"
                },
                {
                  name: "controlBar",
                  align: "blabs",
                  x: 0,
                  y: 0,
                  children: [{
                      name: "progress",
                      align: "blabs",
                      x: 0,
                      y: 44
                    },
                    {
                      name: "playButton",
                      align: "tl",
                      x: 15,
                      y: 12
                    },
                    {
                      name: "timeDisplay",
                      align: "tl",
                      x: 10,
                      y: 7
                    },
                    {
                      name: "fullScreenButton",
                      align: "tr",
                      x: 10,
                      y: 12
                    },
                    {
                      name: "subtitle",
                      align: "tr",
                      x: 15,
                      y: 12
                    },
                    {
                      name: "volume",
                      align: "tr",
                      x: 5,
                      y: 10
                    },
                  ],
                },
              ],
            },
            function (player) {
              console.log("The player is created.");
            }
          );
        } else {
          this.$toast(res.msg);
        }
      } else if (type == "cc") {
        console.log('cc播放', id)
        // let height = document.getElementById('player').style.height
        // console.log(height)
        this.player = window.createCCH5Player({
          vid: id,
          siteid: "23EEB4513490D389",
          // vid: 'AA22BDEE61E7455A9C33DC5901307461',
          // siteid: "2661F9756E5C832E",
          autoStart: true,
          playertype: 1,
          isShare: "false",
          banDrag: "false",
          width: "100%",
          height: "480px",
          loop: "true",
          showFullScreenBtn: "false",
          useX5Play: "true",
          isShowUrlBtn: "false",
          isShowMoreBtn: "false",
          objectFit: "cover", // contain 、 fill 、 cover
          parentNode: "#player",
        });
        // this.player = window.createCCH5Player({
        //             vid: id,
        //             siteid: "23EEB4513490D389",
        //             autoStart: true,
        //             width: "100%",
        //             height: "380px",
        //             parentNode: "#player"
        //         });
        window.on_h5player_error = (err) => {
          console.log('失败')
          console.log(err)
        }
        window.on_CCH5player_ready = (obj)=>{
          console.log('ready')

        };
        window.on_CCH5player_play = (obj) =>{
          this.videPlay()
        }
        window.on_CCH5player_pause = (obj) =>{
          this.videoPause()
        }
      }
      if(this.type=='ali' || this.type=='url'){
        this.player.on('play',()=>{
          this.videPlay()
        })
        this.player.on('pause',()=>{
          this.videoPause()
        })
      }
      
      
        
    },
    videPlay(){
      console.log('播放')
      // console.log(this.player.getDuration())
      this.totalTime =  parseInt(this.player.getDuration())
      this.timeStart = new Date()
    },
    videoPause(){
      console.log('暂停11')
      this.totalTime =  parseInt(this.player.getDuration())
      this.currentTime = parseInt(this.type=='ali' || this.type=='url' ? this.player.getCurrentTime() : this.player.getPosition())
      this.duration = this.timeStart ? parseInt((new Date().getTime() - this.timeStart.getTime()) / 1000) : 0
      this.addHistory()
    },
    async addHistory() {
      let data = this.currentInfo
      console.log(data)
      console.log(this.duration)
      if(!data || this.duration<5) return
      let res = await this.$api({
          method:'post',
          url:'/api/users/flat/record',
          data:{
          lesson_id: data.lesson_id,
          goods_course_id: data.goods_course_id,
          status: this.currentTime && this.currentTime===this.totalTime ? 1 : 0,
          duration: this.duration,
          flat_id: this.plat,
          playmode_id: data.id
        }
      })
      // if (res.code == 0) {
      //   console.log(res.data);
      // }
      this.timeStart = 0
    },
  },
  computed: {
    plat() {
      return this.$store.state.plat_id;
    },
    currentInfo(){
      return this.$store.state.currentVideoInfo
    }
  },
  beforeRouteUpdate(to, from, next) {
    console.log("beforeRouteUpdate");
    console.log(to);
    // this.id = to.query.id;
    // this.getData();
    this.id = this.$route.query.id;
      this.goods_id = to.query.goods_id;
      this.videoId = to.query.videoId;
      this.type = to.query.type;
      this.name = to.query.name;
      this.from = to.query.from;
      this.url = to.query.url ? to.query.url : "";
      this.player.dispose();
      this.getInit()
    next();
  },
};
</script>

<style lang="scss" scoped>
@import url("https://service.wormhoo.com/libs/js/vant2/index.css");

.page {
  min-height: calc(100vh - 230px);
  overflow: hidden;
  margin: 0 auto;
  display: flex;
  align-items: flex-start;

  .left {
    margin-left: 40px;
    width: 300px;
    overflow: hidden;
    margin-top: 20px;
  }

  .classify {
    background: #fff;
    box-shadow: 0 5px 10px #33333308;
    border-radius: 12px;
    flex: 0 0 300px;
    display: flex;
    flex-wrap: wrap;
    padding: 10px 10px;

    .classify-title {
      font-size: 20px;
      padding: 0 20px 0;
      line-height: 1;
    }

    .classify-item {
      width: 100%;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #333;
      font-size: 18px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 14px 20px;

      &:hover {
        color: #a40001;
      }
    }

    .active {
      color: #a40001;
      font-weight: 600;
    }
  }

  .hot-title {
    font-size: 22px;
    font-weight: 500;
    padding: 0 20px 15px;
    width: 100%;
    display: block;
    margin-bottom: 10px;
  }

  .hot-item {
    width: 100%;
    display: flex;
    display: flex;
    align-items: center;
    border-radius: 10px;
    padding: 10px;
    cursor: pointer;
    position: relative;
      .tip{
        position: absolute;
        top:10px;
        left:10px;
        z-index: 1;
        img{
          width: 40px;
        }
      }
    &:hover {
      background: #f8f8f8;
    }

    .cover {
      flex: 0 0 84px;
      height: 84px;
      margin-right: 10px;
      ::v-deep img{
        border-radius: 6px;
      }
    }

    .detail {
      flex: auto;

      .title {
        font-size: 16px;
        font-weight: 500;
      }

      .subtitle {
        font-size: 12px;
        color: #666;
      }
    }
  }

  .right {
    flex: 0 0 860px;
    margin-top: 20px;
    background: #fff;
    border-radius: 10px;
    padding: 20px 30px 50px;
    box-shadow: 0 5px 10px #33333308;

    h2 {
      margin: 0;
    }
  }

  .player {
    width: 100%;
    height: 480px;
    margin: 30px auto;
    padding-bottom: 0;
    background: #000;
  }

  .player2 {
    width: 100%;
    margin: 30px auto;
    background: #000;
  }
}
</style>

<style lang="scss">
  video{
    height: 480px;
  }
</style>
